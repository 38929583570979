@import "../../../../assets/styles/vars.scss";
@import "../../../../assets/styles/mixins.scss";
.dropdown-menu {
  width: 195px;
  border: 1px solid $secondary100;
  background-color: $white;
  li:not(:last-child) {
    border-bottom: 1px solid $secondary100;
  }
  a,
  button {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 16px;
    font-size: 14px;
    color: $gray;
    font-weight: 500;
    text-align: left;
    transition: all 0.3s;
    min-height: 40px;

    &:hover {
      background-color: $primary100;
      color: $black;
    }
  }
}
