@import "../../../assets/styles/vars.scss";

.welcome {
  position: relative;
  display: grid;
  padding: 20px 0;
  min-height: 324px;
  margin-bottom: 70px;
  @media (max-width: $mobile) {
    display: block;
    margin-bottom: 20px;
    padding: 0 0 20px;
  }
}

.welcome__img {
  position: absolute;
  top: 0;
  right: 0;
  @media (max-width: $laptop) {
    width: 35%;
    top: 15%;
  }
  @media (max-width: $tablet) {
    display: none;
  }
  @media (max-width: $mobile) {
    display: block;
    width: 100%;
    position: relative;
    order: 1;
  }
}

.welcome__inner-block {
  height: 100%;
  padding: 0 40px;
  background: url(../../../assets/img/icons/metr.svg) no-repeat center right;
  background-color: $ghostWhite;
  border: 1px solid $primary100;
  border-radius: 20px;
  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
  }
}

.welcome__inner-block_with-side-content {
  display: flex;
  justify-content: space-between;
  @media (max-width: $laptop) {
    flex-direction: column;
  }
}

.welcome__side-content {
  width: 626px;
  padding: 26px 0;
  max-width: 100%;
  @media (max-width: $laptop) {
    padding-top: 0;
  }
}

.welcome__container {
  width: 620px;
  max-width: 100%;
  padding-bottom: 20px;
  @media (max-width: $mobile) {
    width: 100%;
  }
}

.welcome__tabs {
  margin-top: 30px;
  margin-bottom: 20px;

  .tab-list {
    gap: 60px;
    @media (max-width: $mobile) {
      gap: 20px;
    }
  }
}

.welcome__advantage {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.welcome__advantage-img {
  display: flex;
  align-items: center;
}
