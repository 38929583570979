.jk-info {
  width: 540px;
  max-width: 100%;

  .section-title {
    margin-top: 0;
  }
}

.jk-info__btn {
  margin-top: 26px;
}