@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-family: inherit;
  transition: all 0.3s;
  line-height: 1;
  flex-shrink: 0;
  @include medium;

  &[disabled] {
    cursor: default;
  }
}

.btn_primary {
  color: $white;
  background-color: $primary700;
  &:hover {
    background-color: $primary500;
  }
  &:active {
    background-color: $primary900;
  }
  &:disabled {
    color: $gray;
    background-color: $primary100;
  }
}

.btn_primary.btn_label {
  &:hover {
    background-color: $primary700;
  }
  &:active {
    background-color: $primary700;
  }
}

.btn_secondary {
  color: $secondary1000;
  background-color: $primary100;
  &:hover {
    background-color: $primary200;
  }
  &:active {
    background-color: $primary300;
  }
  &:disabled {
    color: $gray;
    background-color: $primary100;
  }
}

.btn_secondary-gray {
  color: $gray;
  background-color: $primary100;
  &:hover {
    color: $secondary1000;
    background-color: $primary200;
  }
  &:active {
    color: $secondary1000;
    background-color: $primary300;
  }
  &:disabled {
    color: $gray;
    background-color: $primary100;
  }
}

.btn_secondary-red {
  color: $secondary1000;
  background-color: $primary100;
  &:hover {
    color: $secondary1000;
    background-color: $tertiaryError;
  }
  &:active {
    color: $secondary1000;
    background-color: $tertiaryError;
  }
  &:disabled {
    color: $gray;
    background-color: $tertiaryError;
  }
}

.btn_secondary.btn_label {
  &:hover {
    color: $secondary1000;
    background-color: $primary100;
  }
  &:active {
    color: $secondary1000;
    background-color: $primary100;
  }
}

.btn_tertiary {
  color: $black;
  background-color: $primary200;
  &:hover {
    color: $black;
    background-color: $primary200;
  }
  &:active {
    color: $black;
    background-color: $primary200;
  }
  &:disabled {
    color: $black;
    background-color: $primary200;
  }
}

.btn_error.btn_label {
  color: $white;
  background-color: $tertiaryError;
  &:hover {
    color: $white;
    background-color: $tertiaryError;
  }
  &:active {
    color: $white;
    background-color: $tertiaryError;
  }
}

.btn_size_default {
  min-width: 190px;
  padding: 14px 36px;
  min-height: 50px;

  @include base-16;

  @media (max-width: $mobile) {
    padding: 8px 24px;
    @include base-12;
    min-height: 33px;
    min-width: auto;
  }
}

.btn_size_m {
  padding: 10px 30px;
  min-height: 40px;
  @include base-14;
  @media (max-width: $mobile) {
    padding: 10px;
    min-height: 30px;
  }
}

.btn_size_s {
  padding: 8px 24px;
  min-height: 33px;
  @include base-12;
  @media (max-width: $mobile) {
    padding: 8px;
    min-height: 30px;
  }
}

.btn_size_xs {
  padding: 6px 12px;
  min-height: 27px;
  @include base-11;
  @media (max-width: $mobile) {
    padding: 8px;
  }
}

.btn_label {
  cursor: default;
  border-radius: 100px;
}

.btn_full {
  width: 100%;
  @media (max-width: $mobile) {
    width: auto;
  }
}

.btn_clean {
  min-width: auto;
  min-height: auto;
  background: none;
  padding: 0;
  color: inherit;
  font-weight: normal;
  font-size: inherit;
  &:hover,
  &:active {
    background: none;
  }
  &:focus-within {
    outline: none;
    border: none;
  }
}
