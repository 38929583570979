@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.history {
  display: grid;

  @media (max-width: $mobile) {
    overflow-x: auto;
  }
}

.history-item {
  display: flex;
  gap: 30px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 13px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: $primary700;
    display: none;
  }
  &:not(:last-of-type) {
    padding-bottom: 13px;
    &::before {
      display: block;
    }
  }

  &_nextpoint {
    color: $secondary300;

    &::before {
      background-color: $secondary300;
    }
    .history-item__descr {
      color: $secondary300;
    }
  }
}

.history-item__title {
}

.history-item__icon {
  display: flex;
}

.history-item__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.history-item__descr {
  color: $gray;
}

.history-item__icon {
  position: relative;
  z-index: 1;
}
