@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.collapse-toggler {
  display: inline-flex;
  align-items: center;
  gap: 8px;

  @include h8;
  @include text-blue;

  & > .collapse-icon {
    margin-top: 2px;
  }

  &_opened > .collapse-icon {
    transform: rotate(180deg);
  }
}

.collapse-theme-faq__question {
  display: flex;
  justify-content: space-between;
  text-align: left;
  background-color: $ghostWhite;
  width: 100%;
  font-size: 24px;
  color: $black;
  padding: 14px 24px;
}

.collapse-theme-faq__answer {
  width: 100%;
  font-size: 16px;
  color: #909090;
  padding: 0 24px;
}
