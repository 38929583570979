@import "../../../assets/styles/vars.scss";

.back-btn {
  color: $primary200;
  justify-content: flex-start;

  &:hover {
    color: $primary400;
  }

  &:active {
    color: $primary300;
  }
}
