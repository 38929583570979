.h1 {
  font-weight: 500;
  font-size: 48px;
  
}

.h2 {
  font-weight: 500;
  font-size: 40px;
  
}

.h3 {
  font-weight: 700;
  font-size: 36px;
  
}

.h4 {
  font-weight: 500;
  font-size: 32px;
  
}

.h5 {
  font-weight: 500;
  font-size: 24px;
  
}

.h6 {
  font-weight: 700;
  font-size: 20px;
  
}

.h7 {
  font-weight: 500;
  font-size: 18px;
}

.h8 {
  font-weight: 500;
  font-size: 16px;
}

.body-large {
  font-weight: 400;
  font-size: 20px;
}

.body-default {
  font-weight: 400;
  font-size: 16px;
  
}

.body-small {
  font-weight: 400;
  font-size: 14px;
  
}

.body-smaller {
  font-weight: 400;
  font-size: 12px;
  
}

.body-least {
  font-weight: 400;
  font-size: 11px;
  
}

.base-24 {
  font-size: 24px;
}

.base-20 {
  font-size: 20px;
}

.base-18 {
  font-size: 18px;
}

.base-16 {
  font-size: 16px;
}

.base-14 {
  font-size: 14px;
}

.base-12 {
  font-size: 12px;
}

.base-11 {
  font-size: 11px;
}

.base-10 {
  font-size: 10px;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.text-gray {
  color: $gray;
}

.text-blue {
  color: $default;
}
