@import "../../../assets/styles/vars.scss";

.copy-button {

}

.copy-button__icon {
  color: $primary700;
}

.copy-button__success-icon {
  color: $tertiaryOkey;
}
