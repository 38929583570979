$primary1000: #25769c;
$primary900: #2a82ac;
$primary800: #2e8dbc;
$primary700: #3399cc;
$primary600: #52a8d5;
$primary500: #70b7dd;
$primary400: #8fc7e6;
$primary300: #aed6ee;
$primary200: #cce5f7;
$primary100: #ebf4ff;

$default: $primary700;
$hover: $primary500;
$pressed: $primary900;
$disabled: $primary100;

$secondary1000: #000000;
$secondary900: #1d1d1d;
$secondary800: #3a3a3a;
$secondary700: #565656;
$secondary600: #737373;
$secondary500: #909090;
$secondary400: #adadad;
$secondary300: #cacaca;
$secondary200: #e7e7e7;
$secondary100: #f5f5f5;

$default2: $secondary100;
$hover2: $secondary200;
$pressed2: $secondary300;
$disabled2: $secondary500;

$tertiaryError: #ff6e8c;
$tertiaryOkey: #00d1b4;
$tertiaryAttention: #ff9900;

$white: #ffffff;
$ghostWhite: #f9fbff;
$gray: #909090;
$black: #1d1d1d;
$white-opacity: rgba(255, 255, 255, 0.5);

$laptop: 1362px;
$tablet: 1023px;
$mobile: 767px;
$mobile-xs: 374px;