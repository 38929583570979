@import "../../assets/styles/vars.scss";
@import "../../assets/styles/mixins.scss";
.scheme {
  position: relative;
  @media (max-width: $mobile) {
    padding-bottom: 100px;
  }
}
.scheme-block {
  padding-bottom: 65px;
  background: url("./icons/arrow-bottom.svg") no-repeat 300px bottom;
  @media (max-width: $laptop) {
    background-position-x: 220px;
  }
  @media (max-width: $mobile) {
    background: none;
    padding-bottom: 0;
    position: absolute;
    top: 200px;
  }
}

.scheme-block__inner {
  display: grid;
  align-items: center;
  padding: 20px;
  background-color: $ghostWhite;
  border: 1px solid $primary100;
  border-radius: 20px;
  gap: 20px;

  grid-template-columns: 275px 275px 405px 250px;

  @media (max-width: $laptop) {
    grid-template-columns: 228px 200px 306px 172px;
    gap: 16px;
    padding: 16px;
  }

  @media (max-width: $tablet) {
    grid-template-columns: 296px 267px;
    gap: 20px 30px;
    padding: 16px;
  }

  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

.scheme-block__item {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: $laptop) {
    font-size: 14px;
    gap: 17px;
    img {
      width: 33px;
    }
  }

  @media (max-width: $tablet) {
    gap: 10px;
  }
}

.scheme-road .btn_label {
  border-radius: 4px;
  min-width: 0;
  padding: 14px 20px;

  @media (max-width: $laptop) {
    font-size: 14px;
    min-height: 36px;
    padding: 8px;
  }
}

.scheme-road {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;

  @media (max-width: $tablet) {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
  }

  @media (max-width: $mobile) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
  }

  .logo {
    margin-left: 5px;
    @media (max-width: $laptop) {
      width: 115px;
    }
    @media (max-width: $mobile) {
      width: 235px;
      margin-left: 0;
    }
  }
}

.scheme-road__item,
.scheme-road__item-double {
  background-image: url("./icons/arrow-right.svg");
  background-position: center right;
  background-repeat: no-repeat;
  padding-right: 38px;
  @media (max-width: $laptop) {
    padding-right: 23px;
  }
}

@media (max-width: $laptop) {
  .scheme-road__item:nth-child(4) {
    background-image: none;
    padding-right: 0;
  }
  .scheme-road__item:nth-child(5) {
    order: 3;
  }
  .scheme-road__item:nth-child(6) {
    order: 2;
  }
  .scheme-road__item:nth-child(7) {
    order: 1;
  }

  .scheme-road__item:nth-child(5),
  .scheme-road__item:nth-child(6),
  .scheme-road__item:nth-child(7) {
    padding-right: 0;
    padding-left: 23px;
    background-image: url("./icons/arrow-left.svg");
    background-position-x: left;
    margin-left: 10px;
  }
}

@media (max-width: $mobile) {
  .scheme-road__item:nth-child(n),
  .scheme-road__item-double {
    order: 0;
    background-image: url("./icons/arrow-bottom.svg");
    background-position: center bottom;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 30px;
    margin-left: 0;
  }

  .scheme-road__item:nth-child(2) {
    margin-bottom: 265px;
  }
}

.scheme-road__item-double {
  position: relative;
  .btn_label {
    background-color: $ghostWhite;
    border: 2px solid $primary700;
    &:last-of-type {
      position: absolute;
      left: 0;
      top: -120%;
      width: max-content;

      @media (max-width: $mobile) {
        position: relative;
        margin-left: 10px;
      }
    }
  }
}

.scheme__example {
  margin-top: 100px;

  @media (max-width: $laptop) {
    margin-top: 60px;
  }

  img {
    width: 200px;
    @media (max-width: $laptop) {
      width: 155px;
    }
  }
}
.scheme__example-road {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 355px 342px 350px 284px; // +85px паддинга

  @media (max-width: $laptop) {
    grid-template-columns: 230px 215px 215px 225px;
    justify-content: space-between;
  }
  @media (max-width: $tablet) {
    grid-template-columns: 330px 230px;
    justify-content: center;
  }
  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.scheme__example-block {
  padding: 14px 20px;
  border-radius: 4px;
  background-color: $primary100;
  @media (max-width: $laptop) {
    padding: 10px 16px;
    font-size: 14px;
  }
}

.scheme__example-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 22px;
  &:not(:last-child) {
    padding-right: 85px;
    background-image: url("./icons/arrow-right-lg.svg");
    background-position: top 230px right 15px;
    background-repeat: no-repeat;

    @media (max-width: $laptop) {
      padding-right: 0;
      background: none;
    }

    @media (max-width: $tablet) {
      padding-right: 88px;
      background-image: url("./icons/arrow-right-lg.svg");
      background-position: top 185px right 15px;
      background-repeat: no-repeat;
    }
  }

  @media (max-width: $tablet) {
    background-repeat: no-repeat;
    &:nth-child(2) {
      padding-right: 0;
      padding-bottom: 60px;
      background-image: url("./icons/arrow-bottom.svg");
      background-position: bottom 0 center;
    }

    &:nth-child(3) {
      order: 1;
      padding-right: 0;
    }

    &:nth-child(4) {
      padding-right: 88px;
      background-image: url("./icons/arrow-left-lg.svg");
      background-position: top 185px right -15px;
    }
  }

  @media (max-width: $mobile) {
    &:nth-child(n) {
      background: none;
      padding-right: 0;
      padding-bottom: 0;
      width: 240px;
      margin: 0 auto;
    }
  }
}
