@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.apartment-card {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  border: 1px solid $primary100;
  border-radius: 20px;

  @include body-smaller;

  @media (max-width: $mobile) {
    padding: 20px;
  }
}

.apartment-card__header {
  margin-bottom: 30px;
  span + span {
    margin-left: 6px;
  }
}

.apartment-card__body {
  display: flex;
  gap: 18px;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: $mobile) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.apartment-card__img {
  height: 200px;
  object-fit: contain;
  @media (max-width: $mobile) {
    height: 120px;
  }
}
