@import "../../../assets/styles/vars.scss";

.status {
  padding: 3px 12px;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 500;

  background-color: $secondary100;

  &_success {
    background-color: $primary100;
  }

  &_in-progress {
    background-color: rgba($tertiaryOkey, 0.1);
  }

  &_canceled {
    background-color: rgba($tertiaryAttention, 0.1);
  }

}
