@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.user-menu {
  display: flex;
  @media (max-width: $laptop) {
    width: 100%;
    justify-content: flex-end;
  }
  @media (max-width: $mobile) {
    width: auto;
    justify-content: flex-end;
  }
}

.user-menu__buttons {
  display: flex;
  gap: 24px;

  @media (max-width: $mobile) {
    gap: 10px;
  }
}

.user-menu__interface {
  display: flex;
  align-items: center;
}

.user-menu__profile {
  display: flex;
  justify-content: center;
  align-items: center;

  color: $primary700;

  width: 40px;
  height: 40px;
  border: 1px solid $primary100;
  border-radius: 50%;

  transition: all 0.3s;
  &:hover {
    background-color: $white;
  }
}

.user-menu__dropdown button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary700;
  width: 40px;
  height: 40px;
}
.user-menu__notification-container {
  position: relative;
}
.user-menu__notification {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary700;
  width: 40px;
  height: 40px;
  border: 1px solid $primary100;
  border-radius: 50%;
  transition: all 0.3s;
  margin-right: 5px;
}

.user-menu__notification_items {
  flex-direction: column;
  gap: 8px;
  display: none;
  position: absolute;
  top: 57px;
  z-index: 6;
  right: 0;
  background-color: $white;
  padding: 8px 11px;
  border: 1px solid $primary100;
  border-radius: 10px;
  max-height: 730px;
  overflow: auto;
}
.user-menu__notification_items--open {
  display: flex;
}

.user-menu__notification_items::-webkit-scrollbar {
  width: 8px;
  height: 126px; /* ширина всей полосы прокрутки */
}
.user-menu__notification_items::-webkit-scrollbar-track {
  background: $white; /* цвет зоны отслеживания */
}
.user-menu__notification_items::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: $primary200; /* цвет бегунка */
  border-radius: 4px; /* округлось бегунка */
}

.user-menu__notification-counter {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -30%);

  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 15px;
  height: 15px;
  padding: 0 4px;
  background-color: $primary700;
  border-radius: 100px;
  color: $white;

  @include base-11;
  @include bold;
}

.user-menu__profile {
  margin-right: 5px;
}
