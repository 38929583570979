@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";
.tabs-layout__nav {
  display: flex;
  gap: 14px;

  @media (max-width: $mobile) {
    overflow: scroll;
    white-space: nowrap;
  }
}
