@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.popup-container {
  display: flex;
  position: relative;
  button {
    display: flex;
  }
}

.popup-container__chat {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 28px;
  height: 21px;
  background-color: $secondary100;
}

.popup {
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: 200px;
  padding: 16px 20px;
  padding-right: 40px;
  background-color: $secondary100;
  border-radius: 20px;
  position: absolute;
  bottom: 100%;
  line-height: 1.3;
  z-index: 2;
  @media (max-width: $mobile) {
    right: 0;
  }
}

.card__body-container .popup,
.success-screen .popup {
  bottom: auto;
  top: 10px;
  right: 10px;
  left: auto;
  max-width: 50%;
  text-align: left;
  align-items: flex-start;

  img {
    margin-top: 2px;
  }
}

.success-screen .popup {
  max-width: 80%;
  top: 20px;
}

.card__body-container .popup-container,
.success-screen .popup-container {
  position: static;
}

.card .popup,
.comparison-block_income .popup {
  @media (max-width: $mobile) {
    right: auto;
    left: 0;
  }
}

.popup__info-icon {
  margin-right: 10px;
}

.popup__text {
  @include base-12;
  @include text-gray;
}

.popup_black .popup__text {
  color: $black;
}

.close-btn.popup__close-btn {
  width: 14px;
  height: 14px;
  color: $secondary600;
  position: absolute;
  top: 10px;
  right: 10px;

  svg {
    width: 100%;
    height: 100%;
  }
  &:hover {
    color: $secondary600;
  }
  &:active {
    color: $secondary600;
  }
}
