@import "../../../../assets/styles/vars.scss";
@import "../../../../assets/styles/mixins.scss";

.bottombar {
  padding: 24px 0;
  background-color: $ghostWhite;
  @include base-14;

  @media (max-width: $mobile) {
    padding: 12px 0;
    @include base-11;
    @include medium;
  }
}

.bottombar__block {
  display: flex;
  align-items: center;
  @media (max-width: $laptop) {
    flex-wrap: wrap;
  }
  @media (max-width: $mobile) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.bottombar-nav {
  display: flex;
  gap: 40px;
  padding: 0 40px;
  margin-left: 102px;
  margin-right: auto;

  @media (max-width: $laptop) {
    margin-left: auto;
    margin-right: 0;
    padding: 0;
    gap: 20px;
  }

  @media (max-width: $mobile) {
    margin-left: 0;
    padding: 0;
    flex-direction: column;
    gap: 26px;
    margin-bottom: 16px;
  }
}

.bottombar .bottombar-nav {
  @media (max-width: $mobile) {
    display: none;
  }
}
