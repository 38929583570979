@import "../../../../assets/styles/vars.scss";

.my-tokens {
  display: grid;
  padding-top: 50px;
  gap: 13px;
}

.copy-order-id-button {
  position: absolute;
  top: 5px;
  right: 15px;
  display: flex;
  align-items: center;
  font-size: 11px;
  gap: 4px;

  @media (max-width: $mobile) {
    position: static;
  }
}
