@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.banner {
  padding: 30px 60px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  background-image: url(../../../assets/img/exchange.png),
    linear-gradient(92.39deg, #f1f4ff 0%, #ffffff 51.5%);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  border-radius: 20px;
  border: 1px solid $primary100;

  @media (max-width: $mobile) {
    background-position: top;
    padding: 10px;
    padding-top: 220px;
  }
}

.banner__content {
  width: 472px;
  max-width: 100%;
}

.banner__title {
  margin-bottom: 10px;
}

.banner__text {
  margin-bottom: 30px;
  color: $secondary500;
}

.banner__pic {
  color: $default;
}
