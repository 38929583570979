.slider {
  position: relative;
}

.slider__nav {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 2;

  display: flex;
  gap: 12px;

  color: white;
}

.slider__next svg {
  transform: rotate(180deg);
}

.slider__nav_content {
  &.slider__prev,
  &.slider__next {
    position: absolute;
    bottom: 45px;
    z-index: 10;

  }

  &.slider__prev {
    left: 20px;
  }

  &.slider__next {
    right: 20px;
  }
}
