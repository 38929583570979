@import "../../../../src/assets/styles/vars.scss";
.form__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(125px, 1fr));
  gap: 30px;

  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  & + & {
    margin-top: 30px;
    @media (max-width: $mobile) {
      gap: 15px;
    }
  }

  & + .form__title {
    margin-top: 60px;
  }
}

.form__group {
  margin-top: 30px;

  .form__row + .form__row {
    margin-top: 10px;
  }
}

.form__title {
  margin-bottom: 30px;
}

.form__item {
  &:only-child {
    width: 100%;
  }
}

.form__submit {
  margin: auto;
}

.form__edit {
  display: flex;
  width: 100%;
}

.form__edit-btn {
  margin-left: auto;
  @media (max-width: $mobile) {
    width: 100%;
  }
}

.form__input ~ .popup {
  margin-top: 20px;
}

.form__submit_ml {
  margin-left: auto;
  margin-right: 0;
}

.form__admin-btns {
  display: flex;
  gap: 10px;
  margin-right: auto;
}

.form__preview-image {
  width: 28px;
  height: 28px;
  margin-left: auto;
  object-fit: cover;
  border-radius: 50%;
}

.form__row_grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  .upload-field {
    height: 100%;
  }
}

.form__row_grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  .upload-field {
    height: 100%;
  }
}
input:disabled.form__input_highlighted {
  color: $black;
}
