@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.table {
  display: flex;
  width: fit-content;
  min-width: 460px;
  max-width: 100%;
  gap: 40px;
  // justify-content: space-between;
  padding: 20px 30px;
  border: 1px solid $primary100;
  border-radius: 20px;

  &_no-gap {
    gap: 0;
    width: 100%;
  }

  @media (max-width: $mobile) {
    min-width: 100%;
    padding: 10px;
    overflow: scroll;
    white-space: nowrap;
  }
}

.table__col {
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.table__key {
  margin-bottom: 20px;
  @media (max-width: $mobile) {
    margin-bottom: 0;
  }

  @include text-gray;

  &_hidden {
    visibility: hidden;
  }
}

.table__value {
  display: flex;
  min-height: 27px;
  align-items: center;
  & + & {
    margin-top: 20px;
  }
}

.table_with-labels {
  .table__value {
    min-height: 25px;
    display: flex;
    align-items: baseline;
  }
}

.table_status {
  width: 100%;
  gap: 60px;
  @media (max-width: $mobile) {
    gap: 10px;
  }
}

.table__col_no-wrap {
  white-space: nowrap;
  flex-shrink: 0;
}
