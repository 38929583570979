@import '../../../assets/styles/vars.scss';

.close-btn {
  color: $primary200;

  &:hover {
    color: $primary400;
  }

  &:active {
    color: $primary300;
  }
}