@import "src/assets/styles/vars";

.order-price {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: $ghostWhite;
  padding: 30px 20px;
  border-radius: 20px;
  margin-bottom: 48px;
}
.order-price__block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}