.wallet-button {
  position: relative;
  padding-left: 30px;
  &::before {
    content: "";
    position: absolute;
    left: -3px;
    bottom: 0;
    width: 59px;
    height: 33px;
    background: url("../../assets/img/icons/wallet-bg.svg") no-repeat left;
    z-index: 1;
  }

  svg {
    margin-right: 10px;
  }
}

.wallet-button__container {
  position: relative;
  z-index: 2;
}
