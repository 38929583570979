@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";
.footer {
  padding: 60px 0;
  @media (max-width: $mobile) {
    padding: 0 0 20px;
  }
}

.footer__top {
  display: grid;
  grid-template-columns: 155px 1fr 224px;
  justify-content: space-between;
  padding-bottom: 57px;
  border-bottom: 1px solid $primary300;

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.footer__logos-col {
  @media (max-width: $mobile) {
    display: none;
  }
}

.footer__logos-col .logo {
  color: $primary400;
}

.footer__logos-box {
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer__logos-title {
  @include base-14;
  @include medium;
}

.footer__nav {
  display: grid;
  // grid-template-columns: repeat(auto-fill, 167px);
  justify-content: space-between;

  margin-left: 71px;
  margin-right: 57px;

  @include base-14;

  @media (max-width: $mobile) {
    margin: 0;
    display: block;
  }
}

.footer__nav-item {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: $mobile) {
    gap: 16px;
  }
}

.footer__contacts {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-right: 10px;

  @media (max-width: $mobile) {
    padding: 0;
    margin-bottom: 20px;
    flex-direction: row;
    align-items: center;
  }
}

.footer__contacts-group {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: $mobile) {
    flex-direction: row;
    gap: 16px;
  }
}

.footer__contacts-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .link {
    @include base-20;
    @include medium;
  }
}

.footer__bottom {
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  @media (max-width: $mobile) {
    flex-direction: column;
    gap: 12px;
  }
}

.footer__metr {
  width: 109px;
  display: flex;
  gap: 10px;

  @media (max-width: $mobile) {
    width: 100%;
    align-items: center;
  }
}

.footer__metr .logo {
  color: $primary300;
}

.footer__cookie {
  width: 427px;
  max-width: 100%;
  @include base-11;
  @include text-gray;
}

.footer__sign {
  @include base-11;
  @include text-gray;
}

.footer__contacts-title {
  @include base-14;
  @include medium;
}

.footer__contacts-sign {
  @include base-14;
  @include text-gray;
  @media (max-width: $mobile) {
  }
}
