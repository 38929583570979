.flexbox {
  display: flex;
  gap: 20px;
  max-width: 100%;
  &_align-center {
    align-items: center;
  }
  &_align-start {
    align-items: start;
  }
  &_align-end {
    align-items: end;
  }

  &_direction-column {
    flex-direction: column;
  }
  &_fit-content {
    width: fit-content;
  }
}
