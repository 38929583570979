@import "../../../assets/styles/vars.scss";

.tab-list {
  display: flex;
  gap: 14px;
  @media (max-width: $mobile) {
    overflow: scroll;
    white-space: nowrap;
  }
}

.tab-btn_underline {
  border-bottom: 2px solid transparent;
  padding-bottom: 8px;
  transition: all 0.3s;
  color: $black;
}

.tab-btn_underline.tab-btn_active {
  border-color: $primary700;
}
