@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.comparison-block {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background-color: $ghostWhite;
  border: 1px solid $primary100;
  border-radius: 20px;

  @media (max-width: $mobile) {
    padding: 15px;
  }

  &__pic {
    position: absolute;
    top: 15px;
    right: 10px;
    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.comparison-block__highlighted {
  color: $primary700;
}

.comparison-block__content {
  display: grid;
  gap: 20px;
  position: relative;
  z-index: 1;
}

.comparison-block__title {
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 1;
  margin-bottom: 10px;
  @include h8;

  & + & {
    margin-top: 12px;
  }
}

.comparison-block__price {
  @include h7;
  color: $primary700;
}

.comparison-block__text {
  margin-top: 6px;
  @include base-14;
  @include text-gray;
}

.comparison-block__info {
  display: flex;
}

.comparison__img {
  color: $default;
}

.comparison-block_attention {
  background: $ghostWhite url("../../../assets/img/attention.png") no-repeat
    right center;
}

.comparison-block_income {
  background: $ghostWhite;
  .comparison-block__content {
    width: 100%;
  }
  .comparison-block__box {
    display: flex;
    justify-content: space-between;
  }
  .comparison-block__title,
  .comparison-block__text {
    margin: 0;
  }
  .comparison-block__highlighted {
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;
  }

  .comparison-block__text {
    display: flex;
    align-items: center;
  }
}
