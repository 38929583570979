.notifications__tabs {
  margin-top: 50px;
  margin-bottom: 30px;
}

.notifications__tabs .tab-list {
  gap: 60px;
}

.notifications__operations {
  width: 930px;
  max-width: 100%;
}

.notifications__jk-tabs {
  margin-bottom: 30px;
}