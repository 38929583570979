.dropdown2 {
  position: absolute;
  z-index: 5;
  top: calc(100% + 10px);
  right: 0;
  display: none;
  &_shown {
    display: block;
  }
}
