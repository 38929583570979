@import "../../../assets/styles/vars";

.notification-card {
  display: flex;
  flex-direction: column;
  padding: 14px 14px;
  background-color: $white;
  border: 1px solid $primary100;
  border-radius: 10px;
  width: 660px;
  max-width: 100%;
  & .progress-status {
    font-size: 9px;
  }
}
.notification-card__date {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: $gray;
  gap: 6px;
}
._bg-white {
  background-color: $ghostWhite;
}
._bg-red {
  background-color: rgba(255, 110, 140, 0.1);
}
._hide-card {
  display: none;
}
.notification-card__mini {
  max-width: 426px;
  & .notification-card__date {
    font-size: 12px;
  }
  & .notification-card__desc {
    font-size: 12px;
    color: $black;
  }
  & .notification-jk-card-info__title {
     font-size: 11px;
  }
  & .notification-jk-card-info__value {
    font-size: 11px;
  }
  & .notification-jk-card {
      grid-template-columns: 100px 1fr;
  }
  & .notification-jk-card-info {
    display: flex;
    flex-wrap: wrap;
  }
  & .a-table {
      padding: 10px 10px;
  }
  & .a-table__header, .a-table__item {
    font-size: 11px;
  }
  & .notification-card__notification-types {
    gap: 10px;
    margin-top: 10px
  }
}
.notification-card__notification-types {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px
}
.notification-card__desc {
  word-break: normal;
  overflow-wrap: break-word;
  & a {
    color: $primary700;
  }
}
.new-releases-table .a-table {
  background-color: $white;
  margin-top: 10px;
}
.new-releases-table .a-table__header {
  margin-bottom: 0;
}

.notification-jk-card {
  display: grid;
  grid-template-columns: 180px 1fr;
  position: relative;
  background-color: $white;
  flex-direction: row;
  border-radius: 10px;
  border: 1px solid #EBF4FF;
  overflow: hidden;
  min-height: 91px;
}
.notification-jk-card__header {
  position: relative;
  min-height: auto;
  background-color: $primary500;
}
.notification-jk-card__header_img {
  overflow: hidden;
  position: relative;
  z-index: 3;
  border-radius: 0;
  object-fit: cover;
  height: 100%;
}
.notification-jk-card__body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px 14px;
}
.notification-jk-card-info {
  display: grid;
  grid-template-columns: 1fr 120px 1fr;
  gap: 14px
}
.notification-jk-card-info__title {
  display: block;
  font-size: 12px;
  font-weight: 400;
}
.notification-jk-card-info__value {
  font-size: 11px;
  color: $gray;
}
.notification-card__setting-btns {
  display: grid;
  grid-template-columns: 1fr;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  margin-top: 12px;
}