@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/vars.scss';
.section-title {
  margin-top: 50px;
  margin-bottom: 30px;
  line-height: 1;

  @include h4;

  @media (max-width: $mobile) {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}