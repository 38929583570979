@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.status-bg-gray {
  color: $black;
  background-color: rgba(245, 245, 245, 1);
  &:hover {
    background-color: rgba(245, 245, 245, 1);
  }
}

.status-bg-yellow {
  color: $black;
  background-color: rgba(255, 153, 0, 0.1);
  &:hover {
    background-color: rgba(255, 153, 0, 0.1);
  }
}

.status-bg-blue {
  color: $black;
  background-color: rgb(235, 244, 255);
  &:hover {
    background-color: rgba(235, 244, 255, 1);
  }
}

.status-bg-green {
  color: $black;
  background-color: rgba(0, 209, 180, 0.1);
  &:hover {
    background-color: rgba(0, 209, 180, 0.1);
  }
}

.status-bg-red {
  color: $black;
  background-color: rgba($tertiaryError, 0.1);
  &:hover {
    background-color: rgba($tertiaryError, 0.1);
  }
}
