@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.code-field__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 98px);
  gap: 12px;

  @media (max-width: $mobile) {
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
  }
}

.code-field__label {
  display: block;
  margin-bottom: 12px;
  @include text-gray;
  @include base-12;
}

.code-field .input_lg {
  text-align: center;
  @media (max-width: $mobile) {
    padding: 0;
    width: 100%;
  }
}

.code-field__code-mobile {
  display: none;
  margin-bottom: 15px;
  span {
    color: $primary700;
  }
  @media (max-width: $mobile) {
    display: block;
  }
}
