@import "../../../../assets/styles/vars.scss";
@import "../../../../assets/styles/mixins.scss";
.support__title {
  width: 727px;
  max-width: 100%;
  line-height: 1.4;
}

.support__links {
  display: flex;
  gap: 30px;
  @include base-20;
}

.support__link {
  display: flex;
  padding: 30px;
  border: 1px solid $primary100;
  border-radius: 30px;
}