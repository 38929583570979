@import "../../assets/styles/vars.scss";
@import "../../assets/styles/mixins.scss";
.jk__grid {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 68px;

  @media (max-width: $mobile) {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }
}

.jk__info-grid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 60px;
  @media (max-width: $mobile) {
    margin-top: 20px;
    flex-direction: column;
    gap: 20px;
  }
}

.jk__info-tokens {
  display: grid;
  max-width: 100%;
  width: 640px;
  gap: 30px;
}

.jk__banner {
  margin-top: 60px;
}

.jk__title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.jk__sub {
  color: $gray;
  @include body-small;
}
