@import '../../../assets/styles/vars.scss';
@import '../../../assets/styles/mixins.scss';
.checkbox {
  cursor: pointer;

  @include base-14;
  @include text-gray;
}

.checkbox__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.checkbox__control {
  display: inline-flex;
}

.checkbox_checked .checkbox__control {
  color: $primary700;
}

.checkbox_with-label {
  display: flex;
  align-items: center;
  gap: 10px;
}