@import "../../../assets/styles/vars.scss";

.timer-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: $ghostWhite;
  border: 1px solid $primary100;
  border-radius: 20px;
}

.timer-block__title {
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  img {
    margin-right: 4px;
  }
}

.timer-block__container {
  display: flex;
  align-items: center;
}

.timer-block__key {
  margin-bottom: 2px;
  color: $primary700;
  line-height: 1;
}

.timer-block__col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer-block__separator {
  margin: 0 12px;
}

.timer-block_sm {
  padding: 10px 20px;
}

.timer-block_lg {
  width: 640px;
  padding: 14px 30px;
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-areas:
    "title title pic"
    "box box pic";

  .timer-block__title {
    margin-top: 6px;
    margin-bottom: 6px;
    grid-area: title;
  }

  .timer-block__box {
    grid-area: box;
  }

  .timer-block__pic {
    grid-area: pic;
    color: $default;
  }
}
