@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.input {
  max-width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  text-align: center;
  border: 1px solid $primary100;
  border-radius: 4px;
  font-weight: 400;

  &:focus {
    border-color: $primary700;
    outline: none;
  }

  &_error,
  &_error:focus {
    border-color: $tertiaryError;
  }

  &:disabled:not(.input_black),
  &_disabled:not(.input_black) {
    color: $secondary200;
    background-color: $white;
    border-color: $secondary100;
    &::placeholder {
      color: $secondary200;
    }
  }

  &::placeholder {
    color: $gray;
  }
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.input-wrapper__label {
  margin-bottom: 8px;

  @include text-gray;
}

.input-wrapper__edit-box {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}

.input-wrapper__edit-btn {
  width: fit-content;
  color: $black;
  transition: all 0.3s;
  &:hover {
    color: $primary700;
  }
}

.input_lg {
  text-align: left;
  height: 56px;
  font-size: 20px;
}

.input-error {
  display: block;
  color: $tertiaryError;
  margin-top: 10px;

  @include base-12;
}

.input-error__reg-form {
  text-align: center;
  margin-top: 10px;
}

.input_textarea {
  resize: vertical;
  min-height: 140px;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 1.4;
}

.input-slider {
  width: 100%;
  color: #29cca3;
  font-size: 12px;

  margin-top: -10px;
}

.input-slider__bar {
  input[type="range"] {
    -webkit-appearance: none;
    margin-right: 15px;
    width: calc(100% - 6px);
    margin-left: 3px;
    margin-right: 3px;
    height: 2px;
    background-color: #39c;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 0 2px #39c inset;
    transition: background 0.3s ease-in-out;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
}

.input-slider__control {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.input-slider__label {
  margin-top: 6px;
}
