@import "../../../assets/styles/mixins.scss";
@import "../../../assets/styles/vars.scss";

.InfoPair {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.InfoKey {
  @include text-gray;
  width: max-content;
  white-space: nowrap;
}

.InfoValue {
  text-align: right;
}
