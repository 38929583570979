@import '../../../assets/styles/vars.scss';
@import '../../../assets/styles/mixins.scss';

.info-block {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 655px;
  max-width: 100%;
  background-color: $ghostWhite;
  border: 1px solid $primary100;
  border-radius: 30px;
  padding: 20px 30px;
}

.info-block__row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
}

.info-block__key {
  @include h7;
}

.info-block__key:not(:only-child) {
  margin-bottom: 4px;
}

.info-block__labels-list {
  display: flex;
  width: fit-content;
  margin-left: auto;
  gap: 10px;
  margin-bottom: 20px;
}