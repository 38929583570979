@import "./mixins.scss";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  background-color: $white;
  color: $black;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  @media (max-width: $mobile) {
    font-size: 14px;
  }
}

a {
  text-decoration: none;
  font-family: inherit;
  font-family: inherit;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
}

button {
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.App {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

.main {
  padding: 40px 0 200px;

  &_pb-100 {
    padding-bottom: 100px;
  }

  @media (max-width: $mobile) {
    padding: 20px 0 20px;
  }
}

.link {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  color: $black;
  transition: color 0.3s;
  &:hover {
    color: $primary700;
  }
  &:active {
    color: $primary900;
  }

  &_gray {
    color: $gray;
    &:hover {
      color: $black;
    }
    &:active {
      color: $secondary1000;
    }
  }

  &_blue {
    color: $primary700;
    &:hover {
      color: $primary500;
    }
    &:active {
      color: $primary900;
    }
  }
}

.link__icon {
  display: inline-flex;
  transition: all 0.3s;
}

.link__icon_blue {
  color: $primary700;
}

.link:hover .link__icon_blue,
.link:active .link__icon_blue {
  color: $primary300;
}

.title {
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 1;

  @include h4;

  @media (max-width: $mobile) {
    margin-bottom: 15px;
    font-size: 18px;
  }
}

.text-title {
  font-weight: bold;
}

.text-block {
  // @include text-gray;

  & + & {
    margin-top: 30px;
  }

  p + p {
    margin-top: 18px;
  }
  .text-title + p {
    margin-top: 12px;
  }
}

.agreement {
  margin-top: 30px;
  @include base-11;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.react-datepicker__input-container input {
  width: 100%;
}

.with-separator {
  border-top: 1px solid $primary200;
  padding-top: 12px;
}

.dl {
  font-size: 14px;
  div {
    display: flex;
    width: 100%;
    padding-bottom: 12px;
    &:not(:last-child) {
      border-bottom: 1px solid $primary100;
      margin-bottom: 12px;
    }
  }

  dt {
    width: 280px;
    @media (max-width: $mobile) {
      width: fit-content;
    }
  }
  dd {
    flex-grow: 1;
    text-align: right;
    white-space: nowrap;
  }
}

.text-center {
  text-align: center;
}

.joint-project {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 100px;
  width: 100%;
  img {
    max-width: 200px;
  }
  @media (max-width: $mobile) {
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}

.highlighted {
  color: $primary700;
}

.caps {
  text-transform: uppercase;
}

.doc-page {
  ol {
    counter-reset: item;
  }
  li {
    display: block;
  }
  li:before {
    content: counters(item, ".") ". ";
    counter-increment: item;
  }

  .doc-page__title {
    font-size: 24px;
  }
}

.list-item {
  margin-top: 15px;
  display: flex;
  gap: 15px;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.gap-10 {
  gap: 10px;
}

.items-center {
  align-items: center;
}
