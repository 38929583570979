@import "../../../assets/styles/vars.scss";

.upload-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  text-align: center;
  border: 1px dashed $primary400;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  background-color: $white;
  height: 74px;
  transition: all 0.3s;
  opacity: 1;
  &:hover,
  &_hovered,
  &[disabled] {
    opacity: 0.6;
  }

  &[disabled] {
    cursor: default;
  }

  svg {
    margin: auto;
  }
}

.upload-field-name-doc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  text-align: center;
  border: 1px solid $primary100;
  border-radius: 6px;
  font-size: 20px;
  color: $primary700;
  background-color: $white;
  height: 74px;
  transition: all 0.3s;
  opacity: 1;
  align-items: center;
  &[disabled] {
    cursor: default;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 24px;
    font-size: 14px;
    max-width: 300px;
  }

  svg {
    margin: auto;
  }
}

.upload-field-edit-doc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  text-align: center;
  color: $primary400;
  border: 1px dashed $primary400;
  border-radius: 6px;
  font-size: 14px;
  background-color: $white;
  height: 74px;
  transition: all 0.3s;
  opacity: 1;

  &[disabled] {
    cursor: default;
  }

  p {
    color: $primary400;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
      transition: all 0.3s;
    }
  }

  a {
    cursor: pointer;
    &:hover {
      opacity: 0.6;
      transition: all 0.3s;
    }
  }

  svg {
    margin: auto;
  }
}

.upload-field__preview {
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  margin-top: 20px;
  gap: 10px;
}

.upload-field__files {
  text-align: left;
  li {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.upload-field__preview-box {
  position: relative;
  &:hover {
    .upload-field__img-preview {
      opacity: 0.5;
    }
    .upload-field__remove-btn {
      display: block;
    }
  }
}

.upload-field__remove-btn {
  position: absolute;
  top: 6px;
  right: 6px;
  display: none;
  z-index: 2;
  color: $tertiaryError;
  &:hover {
    color: $tertiaryError;
  }
}

.upload-field__img-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  transition: all 0.3s;
}

.upload-field__error-message {
  font-size: 12px;
  margin-top: 5px;
  color: $tertiaryError;
}