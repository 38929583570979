@import "../../../../src/assets/styles/vars.scss";

.admin-user__tabs {
  margin-top: 30px;
}

.admin-user__tabs .tab-list {
  gap: 60px;
  @media (max-width: $mobile) {
    gap: 20px;
  }
}
