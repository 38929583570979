@import "../../../assets/styles/vars.scss";

.logo {
  display: flex;
  width: 149px;
  height: 49px;
  color: $primary700;
  svg {
    height: 100%;
    width: 100%;
  }

  @media (max-width: $mobile) {
    width: auto;
    height: 36px;
  }

  @media (max-width: $mobile-xs) {
    height: 16px;
  }
}
