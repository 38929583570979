@import "../../../assets/styles/vars.scss";

.switcher {
  position: relative;
  display: inline-flex;
  width: 40px;
  height: 20px;
  background-color: $primary100;
  border-radius: 100px;
  cursor: pointer;
}

.switcher__control {
  position: absolute;
  top: 50%;
  margin-top: -8px;
  left: 2px;
  transform: translateX(0);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: $tertiaryError;
  transition: all 0.3s;
}

.switcher__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}


.switcher__input:checked ~ .switcher__control {
  background-color: $tertiaryOkey;
  transform: translateX(20px);
}

.switcher_gray {
  background-color: $secondary100;
  .switcher__control {
    background-color: $secondary200;
  }

  .switcher__input:checked ~ .switcher__control {
    background-color: $secondary200;
  }
}