@import '../../../../assets/styles/mixins.scss';

.exchange__title {
  margin-top: 30px;
  margin-bottom: 30px;
  @include base-18;
  @include medium;
}

.exchange__row {
  display: grid;
  width: 745px;
  max-width: 100%;
  grid-template-columns: 1fr 298px;
  gap: 14px;
}

.exchange__sort .tab-list {
  margin-top: 18px;
  margin-bottom: 30px;
}