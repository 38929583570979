@import "../../../assets/styles/vars.scss";
@import "../../../assets/styles/mixins.scss";

.purchase-card {
  position: relative;
  padding: 40px 30px;
  border-radius: 20px 0 0 20px;
  border: 1px solid $primary100;
  background-color: $white;
  width: 400px;
  height: 100%;

  input {
    font-weight: 700;
  }
}

.purchase-card__item {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 15px;
  }
}

.purchase-sell-info__key,
.sign__title {
  display: block;
  color: $primary700;
  margin-bottom: 8px;

  @include h7;
}

.purchase-sell-info__value {
  margin-bottom: 4px;
}

.purchase-card__descr {
  margin-top: 5px;
  @include text-gray;
}

.purchase-card__btn {
  margin-top: 5px;
}

.purchase-card__close-btn {
  position: absolute;
  top: 14px;
  right: 30px;
  @media (max-width: $mobile) {
   bottom: 0;
    right: 20px;
  }
}

.purchase-card_h {
  display: flex;
  flex-direction: column;
  width: 640px;
  max-width: 100%;
  padding: 30px 20px;
  border-radius: 0 20px 20px 0;

  position: absolute;
  z-index: 2;
  right: 15px;
  transform: translateX(-100%);
  transition: all 0.3s;

  .purchase-card__body {
    display: grid;
  }

  .confirm-info div:first-child {
    margin-bottom: 12px;
  }

  .confirm-info__text {
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 1.4;
  }

  .confirm-info__text-bold {
    font-size: 14px;
    font-weight: 600;
  }

  .purchase-card__item + .purchase-card__item {
    margin-top: 0;
  }

  .purchase-card__item {
    &_count {
      order: 0;
    }
    &_token {
      order: 1;
    }
  }
}

.purchase-card_h.purchase-card_opened {
  transform: translateX(0);
  width: 596px;

  @media (max-width: $mobile) {
    width: 100%;
    right: 0;
    padding: 0;
   }
}

.purchase-card_add-comment {
  overflow: auto;
}

.purchase-card_my-sells {
  width: 406px;
  padding: 30px;
  padding-right: 20px;
  flex-direction: row;
  align-items: flex-end;
  right: 0;

  .purchase-card__body {
    grid-template-columns: 212px;
    gap: 12px;
  }
}

.purchase-card__releases-grid {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  row-gap: 8px;
  column-gap: 10px;
}

.purchase-card__release-info {
  display: block;
  font-size: 14px;
  color: $secondary500;
}

.limit-investor {
  display: flex;
  width: 100%;
  height: 37px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  background-color: $ghostWhite;
  border-radius: 10px;
  color: $secondary500;
  font-size: 12px;
  gap: 4px;
}

.sign {
  display: grid;
}
.sign__buy-btn {
  margin-top: 20px;
}

.sign__back-btn {
  position: absolute;
  top: 14px;
  left: 30px;
}

.sign__comission {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  margin-top: 10px;
  background-color: $ghostWhite;
  font-size: 12px;
  color: $gray;
  border-radius: 10px;
}

.sign__grid {
  display: grid;
  gap: 8px;
}

.sign-card {
  display: grid;
  padding: 12px 10px 0;
  border: 1px solid $primary100;
  border-radius: 10px;
  background-color: $ghostWhite;
  color: $gray;

  .sign-card__toggler {
    width: 100%;
    display: flex;
    color: $gray;
    .collapse-icon {
      width: 11px;
      height: 11px;
      margin-left: auto;
    }
  }
}

.sign-card__toggler {
  font-size: 16px;
  padding-bottom: 12px;
}

.sign-card__content {
  display: grid;
  gap: 12px;
  border-top: 1px solid $primary200;
  padding-top: 8px;
  padding-bottom: 12px;
  font-size: 14px;
}
.sign__cert {
  font-size: 12px;
  color: $gray;
  .sign__cert-toggler,
  .sign__cert-content {
    font-size: inherit;
    color: inherit;
  }
}

.success-screen {
  display: grid;
  height: 100%;
  gap: 20px;
  text-align: center;
  //justify-content: center;
  align-content: center;

  @media (max-width: $mobile) {
    margin-top: 40px;
  }
}

.success-screen_inner-padding {
  padding: 19px;
}

.qr-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  & p {
      font-size: 11px;
      font-weight: 500;
  }
  & span {
    cursor: pointer;
    color: rgb(51, 153, 204);
  }
}

.qr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 230px;
  border: 1px solid $primary100;
  border-radius: 20px;
  padding: 30px;
  margin: 0 auto;
}

.sign__btns {
  display: grid;
  gap: 8px;
}

.sign__btns-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}
